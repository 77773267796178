<template>
  <div class="content py-3 ">
    <div class="Title ">
      <p > VIP 会员  </p>
      <div class="Title-lower"></div>
    </div>
    <div class="highlighted-message text-center">
      <p class="highlighted-message-body">
        当前等级：VIP{{$store.state.User?$store.state.User.vip_level:'0'}}
       <!-- ，距离{{Viptips.tips.next_level}}，还差
       {{Viptips.tips.next_lack}} -->
      </p>
    </div>
    <div class="h3 text-white text-center position-relative">
      <div class="mb-2">{{$route.meta.title}}
<!--        <span style="font-size: 16px">VIP{{user_info.vip_level}}</span>-->
      </div>
    </div>
    <div class="content-box pb-1 m-auto">
      <div class="d-flex vip m-auto flex-nowrap content-box-table flex-wrap">
        <div>
          <div class="vip-title">VIP奖励等级</div>
          <div class="vip-title">充值金额</div>
          <div class="vip-title">升级红包</div>
          <div class="vip-title">充值加成</div>
          <div class="vip-title">红包领取</div>
        </div>
        <div  class="content-box-scroll">
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
<!--              :class="index+1 == user_info.vip_level ?'activeVip':''"  -->
              <div class="vip-con" >
                <img style="height: 20px" :src="require('@/assets/images/Nav/wan_vip'+(item.level)+'.png')"
                     alt="">
              </div>
            </div>
          </div>
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
              <div class="vip-con" >
                {{item.threshold}}
              </div>
            </div>
          </div>
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
              <div class="vip-con" >
                {{item.packet}}
              </div>
            </div>
          </div>
          <div class="d-flex">

            <div v-for="(item,index) in VipList" :key="index" class="d-flex">
              <!-- :class="index| borderColor(user_info)" -->
              <div class="vip-con" >
                {{item.rebate}}%
              </div>
            </div>
          </div>
          <div class="d-flex souyouhongbao">
            <div class="w-100">
              <div class="vip-con-btn">
                <div class=" w-100" style="color:aliceblue">
                  所有充值红包系统自动发放
<!--                  请在收支明细中查看-->
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    
    <div class="text-white text-center mt-2 mb-3 position-relative">
      <div class="Title ">
        <p > VIP 等级说明  </p>
        <div class="Title-lower"></div>
      </div>
    </div>
      <div class="desc">
        <div class="item">
          <div class="tilte"><span>会员如何升级？</span></div>
          <div class="info">注册985csgo之后，绑定并校验您的Steam交易链接。达到一定的充值门槛，即可享受所对应的VIP等级的福利。</div>
        </div>
        <div class="item">
          <div class="tilte"><span>是否可以一次上升多个等级？</span></div>
          <div class="info">985csgo支持VIP会员单次充值上升多个等级，即一次充值达到一定等级的，则自动升级至已覆盖的最高的会员等级。并且可以一次性获得已覆盖全部等级所对应的累计升级红包。</div>
        </div>
        <div class="item">
          <div class="tilte"><span>升级红包只能领取1次吗？</span></div>
          <div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。
      
          </div>
        </div>
      
        <div class="item">
          <div class="tilte"><span>在何处查看我的等级？</span></div>
          <div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。</div>
        </div>
        <div class="item">
          <div class="tilte"><span>在何处查看我的等级？</span></div>
          <div class="info">用户登录985csgo之后，点击导航栏“VIP会员”可看到当前等级。</div>
        </div>
      </div>
  </div>
</template>

<script>
  import * as $api from '@/network/api.js'
export default {
  name: "index",
  components: {},
  data() {
    return {
      VipList: [],
      Viptips: {},
    }
  },
  filters: {

  },
  computed: {

  },
  created() {

  },
  mounted() {
    this.init()
    // console.log(this.$store.state.user.vip_level)

  },
  methods: {
    init(){
      $api.getVipLists().then(res => {
        this.VipList = res.data.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.Title{
  background: url('../../assets/images/q2/vip/xian.png') no-repeat center;
  background-size: 100% 100%;
}
  .d-flex{
    display: flex;
    flex-wrap: wrap;
    // width: 20%;
  }
  .w-100{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center
  }
  .text-center{
    text-align: center;
    color: #ffffff;
  }
.activeVip {
  background-color: rgba(0, 0, 0, .35) !important;
}

.souyouhongbao {
  width: 100%;

  @media (max-width: 1200px) {
    width: 1100px;
  }
}

.desc {
  width: 80%;
  margin: 50px auto;

  @media (max-width: 1200px) {
    justify-content: flex-start;
    width: 90%;
    font-size: 12px !important;
    line-height: 1.3;
  }

  & .item {
    margin-bottom: 50px;

    & .tilte {
      margin-bottom: 20px;
      font-size: 14px;
      color: rgb(255, 255, 255);
      // background: url('../../assets/images/q2/vip/');
      // border: 2px solid #1EF0ED;
      // background: #152B52;
      // border-radius: 15px;
      width: auto;
      span{
        border: 2px solid #1EF0ED;
        background: #152B52;
        border-radius: 15px;
        padding: 5px;
      }
    }

    & .info {
      color: rgb(181, 187, 194);
      font-size: 12px;
    }
  }
}

.vip {
  >div{
  display: flex;
  }
  &-title {
    color: #c2c1cf;
    background-color: #28283b;
    border-bottom: 1px solid #222144;
    width:70px !important;
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: url('../../assets/images/q2/vip/top.png') no-repeat center;
    background-size: 100% 100%;
    font-size: 10px;
    color: #34F3FB;
    @media (max-width: 1200px) {
      width: 100px;
    }
  }

  &-silde {
    background-color: #2e2b5b;
    border-right: 1px solid #222144;
    border-bottom: 1px solid #222144
  }

  &-con {
    width: 50px;
    height: 30px;
    padding: 0 10px;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #30303f;
    color: #7BABFF;
    border-right: 1px solid #222144;
    border-bottom: 1px solid #222144;

    &-btn {
      font-size: 14px;
      text-align: center;
      line-height: 68px;
      display: flex;
      justify-content: center;
      // background-color: #30303f;
      flex-direction: row;
      
    }

    &-award {
      width: 80%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #333160;
    }

    & .active {
      background-color: red;
      color: #ffffff;
    }
  }
}

.grade {
  @media (max-width: 1200px) {
    font-size: 12px;
  }
}

.input-code {
  @media (max-width: 1200px) {
    width: 150px !important;
  }
}

.tong-height {
  height: 38px;
  line-height: 38px;
}

.side-box {
  width: 204px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(#191926, .5);

  @media (max-width: 1200px) {
    width: 100%;
    font-size: 12px !important;
  }

  &-box {
    width: 100%;

    @media (max-width: 1200px) {
      width: 100%;
    }

    &-scroll {
      // background-color: #30303f;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 2px !important;
      }
    }

    &-table {
      justify-content: center;

      @media (max-width: 1200px) {
        justify-content: flex-start;

        font-size: 12px !important;
      }
    }
  }
}

.cell {
  padding: 20px;

  @media (max-width: 1200px) {
    padding: 5px;
  }
}

.highlighted-message {
  width: 30%;
  // margin: 10px auto;
  padding: 19px 5px 19px 5px;
  // border: 1px solid #fada62;
  border-radius: 8px;
  margin: 5px;
  // background-color: rgba(250, 218, 98, 0.12);
  background: url('../../assets/images/q2/vip/level.png') no-repeat center;
  background-size: 100% 100%;
  @media (max-width: 1200px) {
    width: 40%;
  }
}


.border-gray-VIP {
  border-bottom: 3px solid #d2d6ff;
}

.border-blue-VIP {
  border-bottom: 3px solid #5968ff;
}

.border-purple-VIP {
  border-bottom: 3px solid #9e6dff;
}

.border-red-VIP {
  border-bottom: 3px solid #af416e;
}

.border-gold-VIP {
  border-bottom: 3px solid #ffba6b;
}
</style>
